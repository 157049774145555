import React, { Component } from "react";
import TransitionablePortalPerProductContent from "./TransitionablePortalPerProductContent";
import { animateScroll as scroll } from "react-scroll";

import {
  Divider,
  Label,
  Button,
  Header,
  Icon,
  Segment,
} from "semantic-ui-react";
import { Trans, withTranslation } from "react-i18next";

class ProductOverviewNormalPerCreateOwnClass extends Component {
  state = { open: false };
  handleClick = () => {
    this.setState((prevState) => ({ open: !prevState.open }));
    if (this.state.open !== true) {
      scroll.scrollTo(window.innerHeight * 0.4);
    }
  };
  handleClose = () => this.setState({ open: false });
  render() {
    const { t } = this.props;

    const { open } = this.state;
    return (
      <Segment placeholder>
        <Header as="h2" icon textAlign="center">
          <Divider hidden />
          <Icon name="settings" />
          <Divider hidden />
          {this.props.item.type}
          <Header.Subheader>
            <Trans components={{ newLine: <br /> }}>
              {this.props.item.description}
            </Trans>
          </Header.Subheader>
          <Divider hidden />
        </Header>
        <Label attached="bottom right">
          <Button
            negative={!open}
            positive={open}
            size="tiny"
            onClick={this.handleClick}
          >
            {t("details")}
          </Button>
        </Label>
        <TransitionablePortalPerProductContent
          key={Math.random()}
          open={open}
          details={this.props.item.details}
          handleClose={this.handleClose}
          handleClick={this.handleClick}
        />
      </Segment>
    );
  }
}
const ProductOverviewNormalPerCreateOwn = withTranslation("productsDetails")(
  ProductOverviewNormalPerCreateOwnClass
);

export default ProductOverviewNormalPerCreateOwn;
