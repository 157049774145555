import React from "react";
import { Form, Segment, Message } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import axios from "axios";
import { Component } from "react";

export class ContactFormClass extends Component {
  state = {};
  t = this.props.t;
  handleChange = (e, { fieldName, errorName, name, value }) => {
    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (value === "") {
      this.setState({
        [errorName]:
          this.t("error.fill-part1") + fieldName + this.t("error.fill-part2"),
      });
      this.setState({ [name]: value });
    } else if (name === "email") {
      if (re.test(value)) {
        this.setState({ [errorName]: false });
        this.setState({ [name]: value });
      } else {
        this.setState({
          [errorName]: this.t("error.email-valid-format"),
        });
        this.setState({ [name]: value });
      }
    } else {
      this.setState({ [errorName]: false });
      this.setState({ [name]: value });
    }
  };
  handleSubmit = (e) => {
    e.preventDefault();
    var error = false;
    if (
      this.state.messageError === true ||
      this.state.phone1Error === true ||
      this.state.emailError === true ||
      this.state.firstnameError === true ||
      this.state.lastnameError === true
    ) {
      error = true;
    }
    if (error === false) {
      this.setState({ isLoading: true });
      axios({
        method: "POST",
        url: "https://epowersbike.com/emailProvider.php",
        data: this.state,
      }).then((response) => {
        if (response.data.status === "success") {
          this.setState({ sendingOk: true });

          this.setState({
            message: "",
            phone1: "",
            firstname: "",
            lastname: "",
            email: "",
            messageError: false,
            phone1Error: false,
            firstnameError: false,
            lastnameError: false,
            emailError: false,
            sendingError: false,
            isLoading: false,
          });
        } else if (response.data.status === "fail") {
          this.setState({ sendingOk: false });
          this.setState({ sendingError: true });
        }
      });
      this.setState({ isLoading: false });
    }
  };
  render() {
    //const { t } = this.props;
    return (
      <Segment>
        <Form
          loading={this.state.isLoading}
          error={this.state.sendingError}
          success={this.state.sendingOk}
          onSubmit={this.handleSubmit}
        >
          <Form.Group widths="equal">
            <Form.Input
              fluid
              label={this.t("input.firstname")}
              placeholder={this.t("input.firstname")}
              fieldName={this.t("input.firstname")}
              required
              name="firstname"
              errorName="firstnameError"
              value={this.state.firstname}
              onChange={this.handleChange}
              error={this.state.firstnameError}
            />
            <Form.Input
              fluid
              label={this.t("input.lastname")}
              value={this.state.lastname}
              placeholder={this.t("input.lastname")}
              fieldName={this.t("input.lastname")}
              onChange={this.handleChange}
              name="lastname"
              errorName="lastnameError"
              error={this.state.lastnameError}
              required
            />
          </Form.Group>
          <Form.Input
            label={this.t("input.email")}
            value={this.state.email}
            placeholder="joe@schmoe.com"
            fieldName={this.t("input.email")}
            onChange={this.handleChange}
            errorName="emailError"
            error={this.state.emailError}
            name="email"
            required
          />
          <Form.Input
            label={this.t("input.phonenumber")}
            onChange={this.handleChange}
            name="phone1"
            fieldName={this.t("input.phonenumber")}
            errorName="phone1Error"
            value={this.state.phone1}
            error={this.state.phone1Error}
            placeholder={this.t("input.phonenumber")}
            required
          />
          <Form.TextArea
            label={this.t("input.message")}
            onChange={this.handleChange}
            name="message"
            fieldName={this.t("input.message")}
            required
            errorName="messageError"
            value={this.state.message}
            error={this.state.messageError}
            placeholder={this.t("input.placeholder.message-in-the-box")}
          />
          <Message
            success
            header={this.t("message.successful")}
            content={this.t("message.successful.content")}
          />
          <Message
            error
            header={this.t("error.message")}
            content={this.t("error.message.content")}
          />
          <Form.Button
            size="massive"
            disabled={
              !this.state.message ||
              !this.state.phone1 ||
              !this.state.firstname ||
              !this.state.lastname ||
              !this.state.email ||
              this.state.messageError ||
              this.state.phone1Error ||
              this.state.firstnameError ||
              this.state.lastnameError ||
              this.state.emailError
            }
          >
            {this.t("input.send")}
          </Form.Button>
        </Form>
      </Segment>
    );
  }
}

const ContactForm = withTranslation("contactus")(ContactFormClass);

export default ContactForm;
