import TranslationEN from "./en";
import TranslationFR from "./fr";
import TranslationDE from "./de";
import TranslationES from "./es";

let resources = {
  en: TranslationEN,
  fr: TranslationFR,
  es: TranslationES,
  de: TranslationDE,
};

export default resources;
