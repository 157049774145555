import React, { Component } from "react";

import { Grid, Segment } from "semantic-ui-react";
import ProductOverviewNormalPerCreateOwn from "./ProductOverviewNormalPerCreateOwn";
import ProductOverviewNormalPerProduct from "./ProductOverviewNormalPerProduct";

export default class ProductOverviewNormal extends Component {
  render() {
    return (
      <Grid centered>
        {this.props.product.map((item, key) => (
          <Grid.Column width={8} key={key} className="mobile hidden">
            <Segment raised>
              {item.imgUrl ? (
                <ProductOverviewNormalPerProduct item={item} />
              ) : (
                <ProductOverviewNormalPerCreateOwn item={item} />
              )}
            </Segment>
          </Grid.Column>
        ))}
      </Grid>
    );
  }
}
