import React from "react";
import { Segment, Header, Divider, Image, Container } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

import Paragraphs from "./Paragraphs";
import TableGenerator from "./TableGenerator";

export default function Garage() {
  const { t } = useTranslation("garage");
  const parag1 = t("parag1", { returnObjects: true });
  const parag2 = t("parag2", { returnObjects: true });
  const parag3 = t("parag3", { returnObjects: true });
  return (
    <Segment size="large">
      <TableGenerator />
      <Divider hidden />
      <Container textAlign="justified">
        <Header as="h3">{t("title.maintenance")}</Header>
        {parag1.map((item, key) => (
          <Paragraphs key={key} title={item.title} paragraph={item.paragraph} />
        ))}
        <Image src={require("../../img/garage/garage.jpg")} centered />
        {parag2.map((item, key) => (
          <Paragraphs key={key} title={item.title} paragraph={item.paragraph} />
        ))}
        <Header as="h3">{t("title.instructions")}</Header>

        {parag3.map((item, key) => (
          <Paragraphs key={key} title={item.title} paragraph={item.paragraph} />
        ))}
        <Divider />
        <Image
          src={require("../../img/garage/garage_002.png")}
          size="huge"
          centered
        />
      </Container>
    </Segment>
  );
}
