import React from "react";
import { Trans, useTranslation } from "react-i18next";

import { Container, Message } from "semantic-ui-react";
export default function Note() {
  const { t } = useTranslation("products");
  return (
    <>
      <Message color="red">
        <Container textAlign="center">
          <i>
            <Trans components={{ newLine: <br /> }}>{t("red.note1")}</Trans>
          </i>
        </Container>
      </Message>
      <Message color="red">
        <Container textAlign="center">
          <i>
            <Trans components={{ newLine: <br /> }}>{t("red.note2")}</Trans>
          </i>
        </Container>
      </Message>
    </>
  );
}
