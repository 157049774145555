import React from "react";
import { useTranslation } from "react-i18next";

import { Container, Message } from "semantic-ui-react";
export default function Note2() {
  const { t } = useTranslation("products");
  return (
    <Message info size="small">
      <Container>
        <Message.Header>{t("small.note")}</Message.Header>
        <p>{t("small.note.content")}</p>
      </Container>
    </Message>
  );
}
