import React, { Component } from "react";
import ProductOverviewDescription from "./ProductOverviewDescription";
import {
  Label,
  Segment,
  TransitionablePortal,
  Divider,
} from "semantic-ui-react";
import { Trans, withTranslation } from "react-i18next";

const LEFT = "LEFT";
const WIDTH = "WIDTH";

const styleGenerator = (mobile, style) => {
  if (style === LEFT) {
    if (mobile) {
      return "2%";
    }
    return "15%";
  }
  if (style === WIDTH) {
    if (mobile) {
      return "96%";
    }
    return "70%";
  }
};

class TransitionablePortalPerProductContentClass extends Component {
  render() {
    const { t } = this.props;
    return (
      <TransitionablePortal
        onClose={this.props.handleClose}
        open={this.props.open}
      >
        <Segment
          className="myScrollToElement"
          tertiary
          style={{
            left: styleGenerator(this.props.mobile, LEFT),
            width: styleGenerator(this.props.mobile, WIDTH),
            position: "absolute",
            top: "40%",
            zIndex: 1000,
          }}
        >
          <Segment size="large">
            {this.props.details
              ? this.props.details.map((item) => (
                  <>
                    <ProductOverviewDescription input={item} />
                    <Divider />
                    <Divider hidden />
                  </>
                ))
              : null}
            <Label attached="bottom left">
              {this.props.mobile ? (
                <Trans components={{ newLine: <br /> }}>
                  {t("details.close.information.mobile")}
                </Trans>
              ) : (
                <>{t("details.close.information.normal")}</>
              )}
            </Label>
            <Label
              as="a"
              color="red"
              onClick={this.props.handleClick}
              attached="bottom right"
            >
              <>{t("details.close")}</>
            </Label>
          </Segment>
        </Segment>
      </TransitionablePortal>
    );
  }
}

const TransitionablePortalPerProductContent = withTranslation("products")(
  TransitionablePortalPerProductContentClass
);
export default TransitionablePortalPerProductContent;
