import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Menu, Dropdown, Container, Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { setLang } from "../../store/lang/actions";

import i18n from "i18next";

const changeLanguage = (lng) => {
  const clng = i18n.language;
  if (clng !== lng) {
    i18n.changeLanguage(lng);
  }
};

const languageHandler = (lang) => {
  const allowedLanguages = ["en", "fr", "de"];

  if (
    allowedLanguages.filter((language) =>
      language.includes(lang.toString().toLowerCase())
    ).length === 1
  )
    return lang;
  else return "en";
};

export default function HeaderMenu() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let url = useRouteMatch("/:lang/:menuItem");
  let urlLangOnly = useRouteMatch("/:lang");
  let menuItem = "home";
  let lang = "en";

  if (url !== null) {
    lang = url.params.lang;
    menuItem = url.params.menuItem;
  }

  if (urlLangOnly !== null && url === null) {
    lang = urlLangOnly.params.lang;
  }
  lang = languageHandler(lang);

  dispatch(setLang(lang));
  changeLanguage(lang);
  //////////////////////////////////

  /////////////////////////////  MENU
  const menuItems = [
    { type: "link", name: "home", content: t("menu.home") },
    { type: "link", name: "products", content: t("menu.product") },
    { type: "link", name: "pricelist", content: t("menu.pricelist") },

    {
      type: "link",
      name: "press",
      content: t("menu.press"),
    },
    {
      type: "link",
      name: "garage",
      content: t("menu.garage"),
    },
    { type: "link", name: "contactus", content: t("menu.contactus") },
  ];

  /////////////////////////////  MENU
  return (
    <Container>
      <Menu size="large" stackable>
        {
          // If mobile
        }

        <Dropdown
          className="mobile only"
          item
          button
          text={
            <>
              <Icon name="bars" size="large" />
              {menuItems.find((e) => e.name === menuItem).content}
            </>
          }
        >
          <Dropdown.Menu>
            {menuItems.map((item, k) => (
              <Dropdown.Item
                key={k}
                name={item.name}
                as={Link}
                active={menuItem === item.name}
                to={"/" + lang + "/" + item.name}
                content={item.content}
              />
            ))}
          </Dropdown.Menu>
        </Dropdown>

        {
          /// If not mobile
        }

        {menuItems.map((item, k) =>
          item.type === "link" ? (
            <Menu.Item
              className="mobile hidden"
              key={k}
              name={item.name}
              active={menuItem === item.name}
              content={item.content}
              to={"/" + lang + "/" + item.name}
              as={Link}
            />
          ) : (
            <Dropdown key={k} item text={item.content}>
              <Dropdown.Menu>
                {item.contentList.map((contentItem, k) => (
                  <Dropdown.Item
                    key={k}
                    name={contentItem.name}
                    as={Link}
                    to={"/" + lang + "/" + item.name + "/" + contentItem.name}
                    content={contentItem.content}
                  />
                ))}
              </Dropdown.Menu>
            </Dropdown>
          )
        )}
      </Menu>
    </Container>
  );
}
