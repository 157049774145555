import React, { Component } from "react";
import { Grid, Embed } from "semantic-ui-react";

const videos = [
  {
    id: "o7H5pWx5Y80",
    placeholder: require("../../img/home/video1_photo.jpg"),
  },
  {
    id: "q1rAmSttJQk",
    placeholder: require("../../img/home/video2_photo.jpg"),
  },
  {
    id: "RsBBQ80BLmU",
    placeholder: require("../../img/home/video3_photo.jpg"),
  },
  {
    id: "e9cH7_tT0B4",
    placeholder: require("../../img/home/video4_photo.jpg"),
  },
];

export default class VideoGrid extends Component {
  render() {
    return (
      <Grid divided={this.props.only === "mobile" ? "vertically" : true}>
        <Grid.Row
          columns={this.props.columns}
          only={this.props.only === "mobile" ? "mobile" : ""}
          className={this.props.only === "mobile" ? "" : "mobile hidden"}
        >
          {videos.map((item) => (
            <Grid.Column>
              <Embed
                id={item.id}
                source="youtube"
                placeholder={item.placeholder}
              />
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
    );
  }
}
