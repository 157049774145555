import React from "react";
import HeaderMenu from "./components/Common/HeaderMenu";
import Header from "./components/Common/Header";
import { useSelector } from "react-redux";
import Home from "./components/Home";
import Distributors from "./components/Distributors";
import Products from "./components/Products";
import ContactUs from "./components/ContactUs";
import Press from "./components/Press";
import PriceList from "./components/PriceList";
import Garage from "./components/Garage";
import { Container } from "semantic-ui-react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Footer from "./components/Common/Footer";
import { getLang } from "./store/lang/selector";

function App() {
  const lang = useSelector(getLang);

  return (
    <div className="App">
      <BrowserRouter>
        <p></p>
        <Container>
          <Header />
        </Container>

        <HeaderMenu />
        <Container>
          <Switch>
            <Route exact path="/">
              <Redirect to={"/" + lang} />
            </Route>

            <Route exact path="/:lang/" component={Home} />
            <Route path="/:lang/home" component={Home} />
            <Route
              path="/:lang/distributors/:country"
              component={Distributors}
            />
            <Route path="/:lang/products">
              <Products />
            </Route>
            <Route path="/:lang/press">
              <Press />
            </Route>
            <Route path="/:lang/pricelist">
              <PriceList />
            </Route>
            <Route path="/:lang/garage">
              <Garage />
            </Route>
            <Route path="/:lang/contactus">
              <ContactUs />
            </Route>
          </Switch>
        </Container>
        <Footer lang={lang} />
      </BrowserRouter>
    </div>
  );
}

export default App;
