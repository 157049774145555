import React from "react";
import { Image, Icon, Grid, List, Dropdown } from "semantic-ui-react";
//import Flag from "react-world-flags";
import { Link } from "react-router-dom";
import { getLang } from "../../store/lang/selector";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function HeaderNormal(props) {
  const { t } = useTranslation();
  const lang = useSelector(getLang);
  return (
    <Grid>
      <Grid.Row className="mobile hidden">
        <Grid.Column width={7} floated="left">
          <Link to="/">
            <Image
              src={require("../../img/common/logo_fekete_hatterre_270.png")}
            />
          </Link>
        </Grid.Column>

        <Grid.Column width={9} floated="right">
          <List floated="right">
            <List.Item>
              <List selection floated="right" horizontal size="mini">
                {props.iconItems.map((item, k) => (
                  <List.Item key={k} style={{ padding: "0.2em 0.2em" }}>
                    <Link to={{ pathname: item.url }} target="_blank">
                      <Icon
                        link
                        name={item.name}
                        inverted
                        color="grey"
                        size="large"
                      />
                    </Link>
                  </List.Item>
                ))}
                <List.Item>
                  <Dropdown
                    button
                    className="icon"
                    floating
                    labeled
                    icon="world"
                    options={props.languageOptions}
                    search
                    value={lang}
                    onChange={props.changeLanguage}
                  />
                </List.Item>
              </List>
            </List.Item>
            <List.Item>
              <List floated="right" relaxed>
                <List.Item>
                  <List.Icon name="mail" size="large" inverted color="grey" />
                  <List.Content>
                    <a href={"mailto:" + t("main.email")}>{t("main.email")}</a>
                  </List.Content>
                </List.Item>
              </List>
            </List.Item>
          </List>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
