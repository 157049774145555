import React, { Component } from "react";
import { List, Header, Container, Segment } from "semantic-ui-react";

export default class FeatureList extends Component {
  render() {
    return (
      <>
        <Segment tertiary>
          <Segment padded>
            <Header as="h3">{this.props.listItems.header}</Header>
            <Container>
              <List bulleted>
                {this.props.listItems.content.map((item, key) => (
                  <List.Item key={key}>{item}</List.Item>
                ))}
              </List>
            </Container>
          </Segment>
        </Segment>
      </>
    );
  }
}
