import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Header } from "semantic-ui-react";
import GeneralTechnicalDetailsList from "./GeneralTechnicalDetailsList";

export default function GeneralTechnicalDetails() {
  const { t } = useTranslation("products");
  const list = t("general.technical.details.list", { returnObjects: true });
  return (
    <Container color="red" textAlign="center">
      <Header as="h3" id="generalTechnicalDetals">
        {t("general.technical.details")}
      </Header>
      {list.map((item, key) => (
        <GeneralTechnicalDetailsList key={key} listItems={item} />
      ))}
    </Container>
  );
}
