import React from "react";
import {
  Image,
  Segment,
  Label,
  Grid,
  Container,
  Embed,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";

export default function Press() {
  const { t } = useTranslation("press");
  const NEW = "NEW";
  const ARCHIVED = "ARCHIVED";
  const articleType = (type) => {
    if (type === NEW) {
      return (
        <Label as="a" color="red" ribbon>
          NEW
        </Label>
      );
    } else if (type === ARCHIVED) {
      return (
        <Label as="a" color="blue" ribbon>
          Archived
        </Label>
      );
    }
  };
  const articlesData = [
    {
      type: NEW,
      imgUrl: "theengine.jpg",
      more: require("../../pdf/press/theengine.pdf"),
    },
    {
      type: NEW,
      imgUrl: "electricrevolution.jpg",
      more: require("../../pdf/press/electricrevolution.pdf"),
    },
    {
      type: NEW,
      imgUrl: "technology_doping.jpg",
    },
    {
      type: NEW,
      imgUrl: "60minutes.jpg",
      more:
        "https://www.cbsnews.com/news/60-minutes-investigates-hidden-motors-and-pro-cycling/",
    },
    {
      type: NEW,
      imgUrl: "newelectricbike.jpg",
      more:
        "https://www.thestar.com/sports/amateur/2017/04/28/new-electric-bike-jolts-cycling-world.html",
    },
    {
      type: NEW,
      imgUrl: "hiddenmagnets.jpg",
      more:
        "https://www.cbsnews.com/news/60-minutes-hidden-magnets-the-next-big-cheat-in-cycling/",
    },
  ];

  const articles = t("articles", { returnObjects: true });
  return (
    <Segment>
      <Grid columns={2}>
        {articles.map((article, key) => (
          <Grid.Column key={key}>
            <Segment raised>
              {articleType(articlesData[key].type)}
              <span>{article.title}</span>
              <Image
                src={require("../../img/press/" + articlesData[key].imgUrl)}
              />
              <Container>
                {article.content ? article.content : null}
                {articlesData[key].more ? (
                  <Container>
                    <a
                      href={articlesData[key].more}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {t("more")}
                    </a>
                  </Container>
                ) : (
                  <></>
                )}
              </Container>
            </Segment>
          </Grid.Column>
        ))}
      </Grid>
      <Segment padded>
        <Label attached="top" size="large">
          {t("youtube video")}
        </Label>
        <Embed
          id="e9cH7_tT0B4"
          placeholder={require("../../img/home/video4_photo.jpg")}
          source="youtube"
        />
      </Segment>
    </Segment>
  );
}
