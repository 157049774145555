import React, { Component } from "react";
import { Header } from "semantic-ui-react";

export default class DistribListContent extends Component {
  render() {
    return (
      <Header as="h2" textAlign="center">
        <Header.Content>
          Try it and get a ride at one of our partnershop!
        </Header.Content>
      </Header>
    );
  }
}
