import React, { Component } from "react";

import { Image, Label, Button } from "semantic-ui-react";
import TransitionablePortalPerProductContent from "./TransitionablePortalPerProductContent";
import { animateScroll as scroll } from "react-scroll";
import { Trans, withTranslation } from "react-i18next";

class ProductOverviewNormalPerProductClass extends Component {
  state = { open: false };

  handleClick = () => {
    this.setState((prevState) => ({ open: !prevState.open }));
    if (this.state.open !== true) {
      scroll.scrollTo(window.innerHeight * 0.4);
    }
  };
  handleClose = () => this.setState({ open: false });
  render() {
    const { open } = this.state;
    const { t } = this.props;

    return (
      <>
        <Image
          fluid
          label={
            <>
              <Label ribbon>
                <b>{this.props.item.type}</b>
              </Label>
              <Label attached="bottom right">
                <Button
                  negative={!open}
                  positive={open}
                  size="tiny"
                  onClick={this.handleClick}
                >
                  {t("details")}
                </Button>
              </Label>
              <Label attached="bottom left">
                <Trans components={{ newLine: <br /> }}>
                  {this.props.item.description}
                </Trans>
              </Label>
            </>
          }
          src={require("../../../img/products/" + this.props.item.imgUrl)}
        />
        <TransitionablePortalPerProductContent
          open={open}
          details={this.props.item.details}
          handleClose={this.handleClose}
          handleClick={this.handleClick}
        />
      </>
    );
  }
}
const ProductOverviewNormalPerProduct = withTranslation("productsDetails")(
  ProductOverviewNormalPerProductClass
);

export default ProductOverviewNormalPerProduct;
