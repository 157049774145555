import React from "react";
import { Image, Icon, Grid, List, Dropdown, Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { getLang } from "../../store/lang/selector";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function HeaderMobile(props) {
  const { t } = useTranslation();
  const lang = useSelector(getLang);
  return (
    <Grid>
      <Grid.Row only="mobile">
        <Grid.Column>
          <Link to="/">
            <Image
              src={require("../../img/common/logo_fekete_hatterre_270.png")}
            />
          </Link>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row only="mobile">
        <Grid.Column>
          <List>
            <List.Item>
              <List selection horizontal size="mini">
                {props.iconItems.map((item, k) => (
                  <List.Item key={k} style={{ padding: "0.2em 0.2em" }}>
                    <Link to={{ pathname: item.url }} target="_blank">
                      <Icon
                        link
                        name={item.name}
                        inverted
                        color="grey"
                        size="large"
                      />
                    </Link>
                  </List.Item>
                ))}
              </List>
            </List.Item>
            <List.Item>
              <Segment size="mini" basic style={{ paddingLeft: "0.5em" }}>
                <Dropdown
                  button
                  className="icon"
                  floating
                  labeled
                  icon="world"
                  options={props.languageOptions}
                  search
                  defaultValue={lang}
                  onChange={props.changeLanguage}
                />
              </Segment>
            </List.Item>
            <List.Item>
              <List relaxed>
                <List.Item>
                  <List.Icon name="mail" size="large" inverted color="grey" />
                  <List.Content>
                    <a href={"mailto:" + t("main.email")}>{t("main.email")}</a>
                  </List.Content>
                </List.Item>
              </List>
            </List.Item>
          </List>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
