import React, { Component } from "react";

import {
  Segment,
  Icon,
  Header,
  Divider,
  Label,
  Button,
} from "semantic-ui-react";
import TransitionablePortalPerProductContent from "./TransitionablePortalPerProductContent";
import { animateScroll as scroll } from "react-scroll";
import { Trans } from "react-i18next";
import { withTranslation } from "react-i18next";

class ProductOverviewMobilePerCreateOwnClass extends Component {
  state = { open: false };

  handleClick = () => {
    this.setState((prevState) => ({ open: !prevState.open }));
    if (this.state.open !== true) {
      scroll.scrollTo(window.innerHeight * 0.4);
    }
  };
  handleClose = () => this.setState({ open: false });
  render() {
    const { t } = this.props;
    const { open } = this.state;
    return (
      <>
        <Segment placeholder>
          <Header as="h2" icon textAlign="center">
            <Divider hidden />
            <Icon name="settings" />
            <Divider hidden />
            {this.props.item.type}
            <Header.Subheader>
              <Trans components={{ newLine: <br /> }}>
                {this.props.item.description}
              </Trans>
            </Header.Subheader>
            <Divider hidden />
          </Header>
        </Segment>
        <Label attached="bottom right">
          <Button
            negative={!open}
            positive={open}
            size="mini"
            onClick={this.handleClick}
          >
            {t("details")}
          </Button>
        </Label>
        <TransitionablePortalPerProductContent
          mobile={true}
          open={open}
          details={this.props.item.details}
          handleClose={this.handleClose}
          handleClick={this.handleClick}
        />
      </>
    );
  }
}

const ProductOverviewMobilePerCreateOwn = withTranslation("productsDetails")(
  ProductOverviewMobilePerCreateOwnClass
);

export default ProductOverviewMobilePerCreateOwn;
