import React, { Component } from "react";
import { Container, Divider, List } from "semantic-ui-react";
import { Trans } from "react-i18next";

export default class ProductOverviewDescription extends Component {
  render() {
    return (
      <>
        <Container>
          <Divider />
          <b>{this.props.input.title}</b>
          {this.props.input.list ? (
            <List as="ul">
              {this.props.input.list.map((item) => (
                <List.Item as="li">{item}</List.Item>
              ))}
            </List>
          ) : null}
          {this.props.input.p1
            ? this.props.input.p1.map((item) => (
                <p>
                  <Trans components={{ italic: <i />, underline: <u /> }}>
                    {item}
                  </Trans>
                </p>
              ))
            : null}
          {this.props.input.list2 ? (
            <List>
              {this.props.input.list2.map((item) => (
                <List.Item>
                  {item.substring(0, 3) === "Ref" ? (
                    <>
                      <b>{item.substring(0, 8)}</b>
                      {item.substring(8, item.length)}
                    </>
                  ) : (
                    item
                  )}
                </List.Item>
              ))}
            </List>
          ) : null}
          {this.props.input.list3 ? (
            <List>
              {this.props.input.list3.map((item) => (
                <List.Item>
                  <b>{item}</b>
                </List.Item>
              ))}
            </List>
          ) : null}
          {this.props.input.list4 ? (
            <List>
              {this.props.input.list4.map((item) => (
                <List.Item>
                  <b>{item}</b>
                </List.Item>
              ))}
            </List>
          ) : null}
          {this.props.input.list5 ? (
            <List>
              {this.props.input.list5.map((item) => (
                <List.Item>
                  <b>{item}</b>
                </List.Item>
              ))}
            </List>
          ) : null}
        </Container>
      </>
    );
  }
}
