import React from "react";
import { Grid, Image, Label } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

export default function WinnerTeamMobile() {
  const { t } = useTranslation("home");

  return (
    <Grid>
      <Grid.Row only="mobile">
        <Grid.Column>
          <Label size="big">{t("Giro d")}</Label>
          <Image src={require("../../img/home/girod.jpg")} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
