import React, { Component } from "react";
import { Trans } from "react-i18next";
import { List, Divider } from "semantic-ui-react";

const components = {
  italic: <i />,
};

export default class GeneralTechnicalDetailsList extends Component {
  render() {
    return (
      <>
        <Divider hidden />
        <List>
          <List.Item>
            <b>
              <Trans components={components}>
                {this.props.listItems.header}
              </Trans>
            </b>
          </List.Item>
          {this.props.listItems.content.map((item, key) => (
            <List.Item key={key}>
              <Trans components={components}>{item}</Trans>
            </List.Item>
          ))}
        </List>
      </>
    );
  }
}
