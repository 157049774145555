import React, { Component } from "react";

import { Grid, Segment } from "semantic-ui-react";
import ProductOverviewMobilePerCreateOwn from "./ProductOverviewMobilePerCreateOwn";
import ProductOverviewMobilePerProduct from "./ProductOverviewMobilePerProduct";

export default class ProductOverviewMobile extends Component {
  render() {
    return (
      <Grid centered>
        {this.props.product.map((item, key) => (
          <Grid.Column key={key} width={16} only="mobile">
            <Segment raised>
              {item.imgUrl ? (
                <ProductOverviewMobilePerProduct item={item} />
              ) : (
                <ProductOverviewMobilePerCreateOwn item={item} />
              )}
            </Segment>
          </Grid.Column>
        ))}
      </Grid>
    );
  }
}
