import React from "react";
import { Trans } from "react-i18next";
import { Header } from "semantic-ui-react";

const components = {
  newLine: <br />,
};

export default function Paragraphs(props) {
  return (
    <>
      <Header as="h3">
        <Trans components={components}>{props.title}</Trans>
      </Header>
      <p>
        <Trans components={components}>{props.paragraph}</Trans>
      </p>
    </>
  );
}
