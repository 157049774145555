import React from "react";
import { Segment, Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

export default function Press() {
  const { t } = useTranslation("garage");
  const tableGenerator = t("componentsWarrantyTable", { returnObjects: true });

  return (
    <Segment raised>
      <Table celled fixed singleLine>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t("table.components")}</Table.HeaderCell>
            <Table.HeaderCell>{t("table.warranty")}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {tableGenerator.map((item, key) => (
            <Table.Row key={key}>
              <Table.Cell>{item.components}</Table.Cell>
              <Table.Cell>{item.warranty}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Segment>
  );
}
