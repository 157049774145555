import React from "react";
import { Segment, Divider, Grid, Image } from "semantic-ui-react";
import Intro from "./Intro";
import ProductOverview from "./ProductOverviewSubComponents/ProductOverview";
import Note from "./Note";
import GeneralTechnicalDetails from "./GeneralTechnicalDetails";
import FeatureList from "./FeatureList";
import Note2 from "./Note2";
import { useTranslation } from "react-i18next";

const bicycleImages = ["framekep.jpg", "midframe.jpg", "midframe2.jpg"];
export default function Products() {
  const { t } = useTranslation("products");
  return (
    <Segment size="large">
      <Intro />
      <ProductOverview />
      <Note />
      <Divider hidden />
      <GeneralTechnicalDetails />
      <FeatureList listItems={t("more", { returnObjects: true })} />
      <FeatureList listItems={t("features", { returnObjects: true })} />
      <Note2 />
      <Divider hidden />
      <Grid columns={bicycleImages.length} divided>
        <Grid.Row>
          {bicycleImages.map((item, key) => (
            <Grid.Column key={key}>
              <Image src={require("../../img/products/" + item)} />
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
    </Segment>
  );
}
