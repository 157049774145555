export const AddressList = [
  {
    country: "france",
    title: "Epowers FRANCE",
    firstRow: "Sales manager and consultant: Sandro Lerici",
    secondRow: "+39 335 6442794 / info@epowers.bike",
  },
  {
    country: "france",
    title: "CYCLES MAZEROLLES",
    firstRow: "41, Rue du Commandant Lisiack",
    secondRow: "F - 17440 Aytré (La Rochelle),    tél. +33 5 46 44 2808",
  },
  {
    country: "france",
    title: "VELOMANIA",
    firstRow: "10, Rue de la Transcevenole",
    secondRow: "F - 43700 Brives Charensac,   tél. +33 4 71 00 9493",
  },
  {
    country: "italy",
    title: "Epowers ITALY",
    firstRow: "Sales manager and consultant: Sandro Lerici",
    secondRow: "+39 335 6442794 / info@epowers.bike",
  },
  {
    country: "italy",
    title: "CICLO SPORT DI ULISSI DANIELA ",
    firstRow: "Via Vecchia Aurelia, 25 - 57021 Donoratico (Livorno)",
    secondRow: "tel. 0565-777149",
  },
  {
    country: "italy",
    title: "BAGLINI ERALDO CICLI",
    firstRow: "Via Argine Vecchio 56019 - Nodica (Pisa)",
    secondRow: "tel. 050-826159",
  },
];
