import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { langReducer } from "./lang/reducer";
//import { menuReducer } from "./menu/reducer";

const reducer = combineReducers({
  lang: langReducer,
  //menu: menuReducer,
});

const logger = (store) => (next) => (action) => {
  next(action);
};

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const enhancer = composeEnhancers(applyMiddleware(thunk, logger));

export const store = createStore(reducer, enhancer);
