import React, { Component } from "react";

import { Image, Label, Button } from "semantic-ui-react";
import TransitionablePortalPerProductContent from "./TransitionablePortalPerProductContent";
import { animateScroll as scroll } from "react-scroll";
import { Trans, withTranslation } from "react-i18next";

class ProductOverviewMobilePerProductClass extends Component {
  state = { open: false };

  handleClick = () => {
    this.setState((prevState) => ({ open: !prevState.open }));
    if (this.state.open !== true) {
      scroll.scrollTo(window.innerHeight * 0.4);
    }
  };

  handleClose = () => this.setState({ open: false });

  render() {
    const { t } = this.props;

    const { open } = this.state;
    return (
      <>
        <Label ribbon>
          <b>{this.props.item.type}</b>
        </Label>
        <Image
          fluid
          src={require("../../../img/products/" + this.props.item.imgUrl)}
        />
        <Label attached="bottom left">
          <Trans components={{ newLine: <br /> }}>
            {this.props.item.description}
          </Trans>
        </Label>
        <Label attached="bottom right">
          <Button
            negative={!open}
            positive={open}
            size="mini"
            onClick={this.handleClick}
          >
            {t("details")}
          </Button>
        </Label>
        <TransitionablePortalPerProductContent
          mobile={true}
          open={open}
          details={this.props.item.details}
          handleClose={this.handleClose}
          handleClick={this.handleClick}
        />
      </>
    );
  }
}
const ProductOverviewMobilePerProduct = withTranslation("productsDetails")(
  ProductOverviewMobilePerProductClass
);

export default ProductOverviewMobilePerProduct;
