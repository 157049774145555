import React from "react";
import { useHistory } from "react-router-dom";

import HeaderMobile from "./HeaderMobile";
import HeaderNormal from "./HeaderNormal";

const iconItems = [
  { name: "twitter", url: "https://twitter.com/EpowersBike" },
  { name: "facebook", url: "https://www.facebook.com/EpowersBikeYourBoost" },
  {
    name: "youtube",
    url: "https://www.youtube.com/watch?v=qvCRUNdmovo&feature=youtu.be",
  },
  {
    name: "instagram",
    url: "https://www.instagram.com/epowersbike/",
  },
  {
    name: "linkedin",
    url: "https://www.linkedin.com/company/epowersbike-hungary-wholesale-kft",
  },
  {
    name: "tumblr",
    url: "https://www.tumblr.com/blog/epowersbike",
  },
  {
    name: "pinterest",
    url: "https://hu.pinterest.com/epowersbike/",
  },
];

const languageOptions = [
  { key: "en", flag: "gb", text: "English", value: "en" },
  { key: "fr", flag: "fr", text: "French", value: "fr" },
  { key: "de", flag: "de", text: "German", value: "de" },
  //{ key: "es", flag: "es", text: "Spanish", value: "es" },
];

export default function Header() {
  let history = useHistory();
  const changeLanguage = (event, data) => {
    const nextLanguage = data.value;
    const currentPathname = window.location.pathname.replace(/\/+$/, "");
    const path = currentPathname.substring(4, currentPathname.length);

    history.push("/" + nextLanguage + "/" + path);
  };
  return (
    <>
      <HeaderMobile
        languageOptions={languageOptions}
        changeLanguage={changeLanguage}
        iconItems={iconItems}
      />
      <HeaderNormal
        languageOptions={languageOptions}
        changeLanguage={changeLanguage}
        iconItems={iconItems}
      />
    </>
  );
}
