import React from "react";
import { Grid, Image, Label } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

export default function WinnerTeamNormal() {
  const { t } = useTranslation("home");

  return (
    <Grid centered>
      <Grid.Row className="mobile hidden">
        <Grid.Column width={14}>
          <Image
            src={require("../../img/home/girod.jpg")}
            label={
              <Label size="large" ribbon>
                {t("Giro d")}
              </Label>
            }
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
