import React from "react";
import { Segment, Container, List, Header } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

export default function PriceList() {
  const { t } = useTranslation("pricelist");
  const listItems = [
    {
      link: require("../../pdf/pricelist/purchase-order-form_2021 EUR_price.pdf"),
    },
    {
      link: require("../../pdf/pricelist/purchase-order-form_2021 USD_price.pdf"),
    },
  ];
  const listTitles = t("download.listTitles", { returnObjects: true });
  return (
    <Segment tertiary>
      <Segment padded>
        <Header as="h3">{t("downloadable.pricelist")}</Header>
        <Container>
          <List>
            {listItems.map((item, key) => (
              <List.Item
                key={key}
                icon="download"
                content={
                  <a href={item.link} rel="noopener noreferrer" target="_blank">
                    {listTitles[key].name}
                  </a>
                }
              />
            ))}
          </List>
        </Container>
        {/* <Header as="h3">{t("download.pricelist")}</Header>
        <Container>{t("download.message")}</Container> */}
      </Segment>
    </Segment>
  );
}
