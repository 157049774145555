import React, { Component } from "react";
import { Grid, Segment, Menu, Divider } from "semantic-ui-react";
import { Link, Route } from "react-router-dom";
import DistribLinkList from "./DistribLinkList.js";
import DistribListContent from "./DistribListContent.js";
const countries = [
  { name: "italy", content: "Italy" },
  { name: "france", content: "France" },
  { name: "belgium", content: "Belgium" },
  { name: "canada", content: "Canada" },
  { name: "nordics", content: "Nordics" },
  { name: "ireland", content: "Ireland" },
  { name: "thailad", content: "Thailand" },
  { name: "malaysia", content: "Malaysia" },
  { name: "singapore", content: "Singapore" },
  { name: "china", content: "China" },
  { name: "russia", content: "Russia" },
  { name: "japan", content: "Japan" },
  { name: "turkey", content: "Turkey" },
  { name: "spain", content: "Spain" },
  { name: "germany", content: "Germany" },
  { name: "united_states", content: "United States" },
];

export default class Distributors extends Component {
  render() {
    return (
      <Segment>
        <Grid>
          <Grid.Column width={4}>
            <Menu fluid vertical tabular>
              {countries.map((country, k) => (
                <Menu.Item
                  key={k}
                  name={country.name}
                  active={this.props.match.params.country === country.name}
                  content={country.content}
                  to={
                    "/" +
                    this.props.match.params.lang +
                    "/distributors/" +
                    country.name
                  }
                  as={Link}
                />
              ))}
            </Menu>
          </Grid.Column>
          <Grid.Column width={12}>
            <DistribListContent />
            <Divider hidden />
            <Segment>
              <Route
                path={"/" + this.props.match.params.lang + "/distributors/:cnt"}
                component={DistribLinkList}
              />
            </Segment>
          </Grid.Column>
        </Grid>
      </Segment>
    );
  }
}
