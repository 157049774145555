import React, { Component } from "react";
import { Header } from "semantic-ui-react";
import { AddressList } from "./AddressList";

//const generateItem = (e) => <Segment>{e.title}</Segment>;
export default class DistribLinkList extends Component {
  render() {
    return (
      <>
        {AddressList.filter(
          (element) => element.country === this.props.match.params.cnt
        ).map((e, k) => (
          <Header key={k} as="h4">
            <Header.Content>
              {e.title}
              <Header.Subheader>{e.firstRow}</Header.Subheader>
              <Header.Subheader>{e.secondRow}</Header.Subheader>
            </Header.Content>
          </Header>
        ))}
      </>
    );
  }
}
