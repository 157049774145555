import React, { Component } from "react";
import { Image } from "semantic-ui-react";
import ContactForm from "./ContactForm";

export default class ContactUs extends Component {
  render() {
    return (
      <>
        <Image src={require("../../img/contactus/contact-us.jpg")} />
        <ContactForm></ContactForm>
      </>
    );
  }
}
