import React from "react";
import { useTranslation } from "react-i18next";
import ProductOverviewMobile from "./ProductOverviewMobile";
import ProductOverviewNormal from "./ProductOverviewNormal";

const product = [
  {
    imgUrl: "WB.jpg",
  },
  {
    imgUrl: "studiofoto-citybike-matyo.jpg",
  },
  {
    imgUrl: "studiofoto-monti.jpg",
  },
  {
    imgUrl: "serial_double.jpg",
  },
  {
    imgUrl: null,
  },
];

export default function ProductOverview() {
  const { t } = useTranslation("productsDetails");
  const translatedProduct = t("product", { returnObjects: true });

  translatedProduct.forEach((item, key) => {
    translatedProduct[key] = {
      ...translatedProduct[key],
      imgUrl: product[key].imgUrl,
    };
  });
  return (
    <>
      <ProductOverviewNormal product={translatedProduct} />
      <ProductOverviewMobile product={translatedProduct} />
    </>
  );
}
