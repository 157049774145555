import React from "react";
import { useTranslation } from "react-i18next";

import { Message } from "semantic-ui-react";
export default function Intro() {
  const { t } = useTranslation("products");
  return (
    <Message color="red">
      <i>{t("red.intro")}</i>
    </Message>
  );
}
