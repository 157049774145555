import React, { Component } from "react";
import {
  Container,
  Segment,
  Header,
  Divider,
  Button,
  Message,
  Image,
  Label,
} from "semantic-ui-react";

import { withTranslation } from "react-i18next";
import VideoGrid from "./VideoGrid";
import WinnerTeamNormal from "./WinnerTeamNormal";
import WinnerTeamMobile from "./WinnerTeamMobile";

class FutureClass extends Component {
  render() {
    const { t } = this.props;
    return (
      <Segment size="large">
        <Container className="mobile hidden" textAlign="center">
          <Button
            color="red"
            as={"a"}
            href={"/" + this.props.lang + "/products#generalTechnicalDetals"}
            size="huge"
          >
            {t("Technical Details")}
          </Button>
        </Container>
        <Container className="mobile only" textAlign="center">
          <Button
            color="red"
            as={"a"}
            href={"/" + this.props.lang + "/products#generalTechnicalDetals"}
          >
            {t("Technical Details")}
          </Button>
        </Container>
        <Divider hidden />
        <Container textAlign="center">
          <Header size="large">{t("FutureStarts")}</Header>
        </Container>
        <Divider hidden />

        <Container className="mobile only">
          {/* <Image size="tiny" src={require("../../img/home/aboutus.png")} /> */}
          <Label color="black" size="small">
            <b>{t("aboutus")}</b>
          </Label>
        </Container>
        <Container className="mobile hidden">
          {/* <Image size="small" src={require("../../img/home/aboutus.png")} /> */}
          <Label color="black" size="huge">
            <b>{t("aboutus")}</b>
          </Label>
        </Container>

        <Container textAlign="justified">
          {/* about us */}

          <Divider hidden />
          {t("paragraph1", { returnObjects: true }).map((item) => (
            <p>{item}</p>
          ))}
          <Divider hidden />
          <VideoGrid
            columns={4}
            only="large screen widescreen tablet computer"
          />
          <VideoGrid columns={1} only="mobile" />
          <Divider hidden />
          <p>{t("epowers is magic")}</p>
          <Image
            src={require("../../img/home/w.jpg")}
            size="tiny"
            floated="left"
          />
          <p>{t("paragraph2")}</p>
          <Divider hidden />
          <Image
            centered
            src={require("../../img/home/bike-to-bike.jpg")}
            size="massive"
          />
          <Divider hidden />
          <p>
            <b>{t("paragraph3")}</b>
          </p>
          <p>{t("paragraph4")}</p>
          <Container textAlign="center">
            <Image.Group size="medium">
              <Image src={require("../../img/home/custom2.jpg")} />
            </Image.Group>
          </Container>
          {t("paragraph5", { returnObjects: true }).map((item) => (
            <p>{item}</p>
          ))}
          <Image src={require("../../img/home/enel.jpg")} centered />
          <Divider hidden />
          <WinnerTeamNormal />
          <WinnerTeamMobile />
          <Message>
            <Container textAlign="center">{t("paragraph6")}</Container>
          </Message>
        </Container>
      </Segment>
    );
  }
}
const Future = withTranslation("home")(FutureClass);

export default Future;
